@import "../../assets/scss/abstract/abstract";

.singleOffer {
  border-style: solid;
  border-width: var(--yps-offer-border-width);
  border-color: var(--yps-offer-border-color);
  border-radius: var(--yps-box-radius-offer);
  box-shadow: var(--yps-offer-box-shadow);
  background: var(--yps-offer-bg-color);
  padding: 10px;
  margin-bottom: 10px;

  display: grid;
  grid-template-columns: 200px minmax(0, 1fr) 1px 200px;
  @include small {
    grid-template-columns: auto minmax(0, 1fr) 1px 190px;
  }
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "offerPicture offerHeading splitter3 offerCompare"
    "offerPicture offerEquip splitter3 offerScore"
    "offerPicture offerPolicies splitter3 offerPrices"
    "splitter1 splitter1 splitter3 offerPrices"
    "offerPartner offerInsurances splitter3 offerPrices"
    "splitter2 splitter2 splitter3 offerButton"
    "offerLabels offerMore splitter3 offerButton"
    "offerDetails offerDetails offerDetails offerDetails";

  @include tablet {
    grid-template-columns: 150px minmax(0, 1fr) auto auto;
    grid-template-areas:
      "offerPicture offerHeading offerHeading offerCompare"
      "offerPicture offerEquip offerEquip offerEquip"
      "offerPicture offerPolicies offerPolicies offerPolicies"
      "splitter1 splitter1 splitter1 splitter1"
      "offerPartner offerInsurances offerInsurances offerInsurances"
      "offerPartner offerInsurances offerInsurances offerInsurances"
      "offerPartner offerInsurances offerInsurances offerInsurances"
      "splitter3 splitter3 splitter3 splitter3"
      "offerPrices offerPrices offerPrices offerPrices"
      "splitter2 splitter2 splitter2 splitter2"
      "offerLabels offerMore offerButton offerButton"
      "offerDetails offerDetails offerDetails offerDetails";
  }

  &.isMobile {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
    gap: 0px 0px;
    grid-template-areas:
      "offerHeading offerPrices"
      "offerPicture offerPrices"
      "offerPicture offerScore"
      "offerEquip offerPartner"
      "splitter1 splitter1"
      "offerPolicies offerPolicies"
      "offerInsurances offerInsurances"
      "offerMore offerMore"
      "offerDetails offerDetails"
      "splitter2 splitter2"
      "offerLabels offerButton";

    .offerCompare {
      display: none;
    }
    .underlineSplitter3 {
      display: none;
    }

    .offerPicture {
      max-width: 120px;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        width: 100%;
      }
    }

    padding-bottom: 0;
  }

  /* CAR-1127
  &--previewOnly {
    grid-template-areas:
      "offerPicture offerHeading splitter3 offerPrices"
      "offerPicture offerEquip splitter3 offerPrices"
      "offerPicture offerPolicies splitter3 offerScore"
      "splitter1 splitter1 splitter3 offerScore"
      "offerInsurances offerInsurances splitter3 offerPartner"
      "splitter2 splitter2 splitter3 offerButton"
      "offerLabels offerMore splitter3 offerButton"
      "offerDetails offerDetails offerDetails offerDetails";
    border: none;
    padding: 0;

    @include tablet {
      grid-template-areas:
      "offerPicture offerHeading splitter3 offerPrices"
      "offerPicture offerEquip splitter3 offerPrices"
      "offerPicture offerPolicies splitter3 offerScore"
      "splitter1 splitter1 splitter3 offerScore"
      "offerInsurances offerInsurances splitter3 offerPartner"
      "splitter2 splitter2 splitter3 offerButton"
      "offerLabels offerMore splitter3 offerButton"
      "offerDetails offerDetails offerDetails offerDetails";
    }

    .underlineSplitter{
      &.underlineSplitter3 {
        @include tablet {
          border-bottom: 0 !important;
          border-left: 1px solid var(--yps-offer-border-color) !important;
          height: auto !important;
        }
      }
    }

    .offerPrices {
      &__pack {
        @include tablet {
          flex-direction: column !important;
          align-items: flex-end !important;
        }
      }

      @include tablet {
        gap: 10px;
        align-items: center;
        padding: 0 0 10px 0;
        flex-direction: column !important;
      }

      .offerAdditionalInfos {
        @include tablet {
          flex-direction: column;
        }
      }

      .offerStatus,
      .offerPrepaid {
        &__label {
          @include tablet {
            display: block;
          }
        }
      }
    }

    .offerPartner {
      @include tablet {
        margin-left: 10px;
      }
    }
  }
  */

  .offerCompare {
    grid-area: offerCompare;
    display: flex;
    justify-content: flex-end;
  }

  .offerDetails {
    grid-area: offerDetails;
    margin-top: 20px;
  }

  .underlineSplitter {
    border-bottom: 1px solid var(--yps-offer-border-color);
    height: 1px;

    &.underlineSplitter1 {
      grid-area: splitter1;
      @include mobile {
        margin: 10px -10px 10px;
      }
    }

    &.underlineSplitter2 {
      grid-area: splitter2;
      @include mobile {
        margin: 10px -10px 0;
      }
    }

    &.underlineSplitter3 {
      border-bottom: 0;
      border-left: 1px solid var(--yps-offer-border-color);
      grid-area: splitter3;
      height: auto;
      @include tablet {
        border-left: 0;
        border-bottom: 1px solid var(--yps-offer-border-color);
        height: 1px;
      }
    }
  }

  .offerHeading {
    grid-area: offerHeading;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .carType {
      font-size: 18px;
      font-weight: 600;
      display: block;
      padding-left: 20px;
      @include mobile {
        padding-left: 0;
      }
    }

    .carNameContainer {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mobile {
        max-width: 250px;
      }
    }

    .carName {
      padding-left: 20px;
      font-weight: 400;
      margin-bottom: 0;
      white-space: nowrap;
      @include mobile {
        padding-left: 0;
        white-space: normal;
      }
    }

    .orSimiliar {
      margin-left: 5px;
      color: var(--yps-text-color-muted);
    }
  }

  .offerEquip {
    grid-area: offerEquip;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding-left: 15px;

    > *:not(:last-child) {
      margin-right: 15px;
      @include small {
        margin-right: 10px;
      }
      @include tablet {
        margin-right: 5px;
      }
    }

    @include mobile {
      padding-left: 0;
      > *:not(:last-child) {
        margin-right: 5px;
      }

      .carIcon {
        flex-direction: column;
        span {
          margin-left: 0;
          font-size: 0.6em;
        }
      }
    }
  }

  .offerPolicies {
    grid-area: offerPolicies;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include mobile {
      justify-content: space-between;
      padding: 0;
    }
  }

  .policy {
    &Container {
      display: flex;
      margin-right: 20px;
      @include mobile {
        margin-right: 0;
      }
    }

    &Label {
      font-size: 12px;
    }

    &Value {
      font-weight: 500;

      &--warning {
        color: var(--yps-warn-color);
      }

      &--success {
        color: var(--yps-success-color);
      }
    }

    &Description {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 6px;
    }

    &Icon {
      &--warning {
        color: var(--yps-warn-color);
      }

      &--success {
        color: var(--yps-success-color);
      }
    }
  }

  .offerPicture {
    grid-area: offerPicture;
    display: flex;
    max-width: 200px;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    img {
      object-fit: contain;
      width: 100%;
    }

    span {
      position: absolute;
      background-color: var(--yps-border-color-light);
      padding: 2px 5px;
      left: 0;
      top: 0;
      font-weight: 500;
      font-size: smaller;
    }
  }

  .offerInsurances {
    grid-area: offerInsurances;
    padding: var(--yps-offer-inside-paddings) 10px var(--yps-offer-inside-paddings) 24px;
    line-height: 24px;

    @include mobile {
      padding: 15px 0 0;
    }

    @include tablet {
      padding: var(--yps-offer-inside-paddings-tablet) 20px var(--yps-offer-inside-paddings-tablet) 20px;
    }

    li {
      margin-bottom: 10px;
    }

    &--inline {
      display: flex;
      flex-wrap: wrap;
      column-gap: 15px;
      margin-bottom: 0;
    }

    &__short {
      padding: 15px 0;
    }
  }

  .offerPartner {
    grid-area: offerPartner;
    padding: var(--yps-offer-inside-paddings) 0;
    max-width: 200px;
    display: flex;
    flex-direction: column;

    @include mobile {
      justify-self: flex-end;
      padding: 0;
      justify-content: flex-end;
    }

    @include tablet {
      padding: var(--yps-offer-inside-paddings-tablet) 0;
    }

    &--rightBottom {
      align-self: flex-end;
      justify-self: flex-end;
      justify-content: flex-end;
      > span {
        justify-content: flex-end;
      }
      .offerAdvantages {
        justify-content: flex-end;
      }
    }
  }

  .offerAdvantages {
    display: flex;
    @include mobile {
      justify-content: flex-end;
    }
    &__badge {
      background: var(--yps-offer-badge-bg-color);
      color: var(--yps-offer-badge-text);
      padding: 2px 5px 2px 2px;
      display: inline-block;
      margin: 10px 0;
      font-weight: 600;
      font-size: small;
      @include tablet {
        font-size: smaller;
      }
    }
  }

  .offerLabels {
    grid-area: offerLabels;
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;
  }

  .offerPrices {
    grid-area: offerPrices;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    text-align: left;
    padding: 0 0 0 15px;
    gap: 10px;

    &__pack {
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      @include tablet {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        gap: 10px;
      }
    }

    @include tablet {
      align-items: center;
      padding: 0 0 10px 0;
      flex-direction: row-reverse;
    }

    .offerPrice {
      font-weight: 700;
      line-height: initial;

      @include mobile {
        line-height: 2rem;
      }

      display: flex;
      align-items: baseline;
      justify-content: right;
      flex-wrap: wrap;

      span {
        &:nth-last-child(2) {
          font-size: 2.5em;
        }
        &:nth-last-child(3) {
          font-size: 2em;
        }

        &:nth-last-child(1) {
          margin-left: 5px;
          font-size: 2em;
        }
        color: var(--yps-featured-price);
      }

      &PerDay {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        color: var(--yps-text-color);
        font-weight: 500;
        span {
          &:nth-last-child(2) {
            font-size: 1em;
          }
          &:nth-last-child(3),
          &:nth-last-child(1) {
            margin-left: 3px;
            font-size: 0.9em;
          }
        }
        @include tablet {
          justify-self: flex-end;
        }
      }

      &__exchanged {
        font-size: smaller;
      }
    }

    .pricePerDayContainer {
      margin-bottom: 5px;
    }

    .pricePerRentalContainer {
      color: var(--yps-text-color-muted);
      margin-bottom: 10px;
    }

    .pricePerDay {
      font-weight: bold;
      margin: 0 5px;
    }

    .pricePerRental {
      margin: 0 5px;
    }

    .pricePerDayLabel {
      font-size: small;
    }

    .pricePerRentalLabel {
      font-size: small;
    }
  }

  .offerMore {
    grid-area: offerMore;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mobile {
      justify-content: flex-start;
    }

    .moreBtn {
      text-align: right;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 5px 10px;
      margin-right: 10px;
      @include tablet {
        margin: 0;
      }

      &:hover {
        text-decoration: none !important;
      }

      @include mobile {
        padding: 0;
      }

      &__title {
        line-height: 20px;
        font-size: 1em;
        display: flex;
        align-items: center;

        @include tablet {
          gap: 5px;
        }
      }

      &__label {
        text-decoration: none;
        font-weight: 500;
        margin-right: 5px;
      }

      &__info {
        padding: 2px;
      }

      &__subtitle {
        display: block;
        font-size: 13px;
        line-height: 18px;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
  }

  .offerButton {
    grid-area: offerButton;
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    @include desktop {
      padding: 0 0 0 15px;
    }
  }

  .bookBtn {
    text-align: right;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin: 5px 0;

    @include mobile {
      margin: 0 -10px 0 0;
    }

    &__total {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
    }

    &__perDay {
      display: block;
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      white-space: nowrap;
      display: flex;
      opacity: 0.7;
    }
  }
}

.offerScore {
  grid-area: offerScore;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mobile {
    margin: 0 0 15px;
  }

  @include tablet {
    display: none;
  }

  .scoreLabel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-self: center;
    text-align: center;

    &__score {
      border-radius: 2px 2px 0 0;
      padding: 0 10px;
      font-size: 13px;
      strong {
        font-size: 16px;
      }
      &.economy {
        border: 1px solid var(--yps-score-economy-border-color);
      }
      &.premium {
        border: 1px solid var(--yps-score-premium-border-color);
      }
      &.basic {
        border: 1px solid var(--yps-score-basic-border-color);
      }
    }

    &__name {
      border-radius: 0 0 2px 2px;
      text-align: center;
      margin: 0;
      padding: 2px;
      color: var(--yps-bg-color);
      font-weight: 500;
      font-size: 10px;
      text-transform: uppercase;

      &.economy {
        background-color: var(--yps-score-economy-bg-color);
        color: var(--yps-score-economy-color);
        border: 1px solid var(--yps-score-economy-border-color);
      }
      &.premium {
        background-color: var(--yps-score-premium-bg-color);
        color: var(--yps-score-premium-color);
        border: 1px solid var(--yps-score-premium-border-color);
      }
      &.basic {
        background-color: var(--yps-score-basic-bg-color);
        color: var(--yps-score-basic-color);
        border: 1px solid var(--yps-score-basic-border-color);
      }
    }
  }
}

.offerPromoRibbon {
  display: flex;
  @include mobile {
    justify-content: flex-end;
  }
  &__badge {
    background: var(--yps-offer-badge-bg-color);
    color: var(--yps-offer-badge-text);
    padding: 2px 5px 2px 2px;
    display: inline-block;
    margin: 10px 0 0;
    font-weight: 600;
    font-size: small;
    @include tablet {
      font-size: smaller;
    }
  }
}

.insuranceItem {
  &__listItem {
    &--warn {
      color: var(--yps-warn-color);
      border-color: var(--yps-warn-color);
    }

    &--danger {
      color: var(--yps-danger-color);
      border-color: var(--yps-danger-color);
    }
  }

  &__container {
    > span {
      display: inline-block;
      padding: 0 5px 0 0;
    }
  }

  &__description {
    display: block !important;
    color: var(--yps-text-color-extra-light);
    font-size: 11px;
  }

  &__liability {
    font-style: italic;
  }

  &__RCTInfo {
    font-style: italic;
  }

  &__excessInfo {
    font-weight: 500;

    &--warn {
      color: var(--yps-warn-color);
    }

    &--danger {
      color: var(--yps-danger-color);
    }
  }
}

.offerJson {
  grid-column-start: 1;
  grid-column-end: 5;
}

.insurancesDesc {
  &__set {
    border-bottom: 1px solid var(--yps-border-color-light);
    padding: 10px 0;

    display: flex;
    flex-direction: column;
  }

  &__caption {
    font-weight: 600;
  }

  &__desc {
    color: var(--yps-text-color-extra-light);
  }
}

.discountLabel {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 10px 0;
  }

  &__upperLabel {
    font-size: 9px;
    text-transform: uppercase;
  }

  &__code {
    padding: 10px;
    border: 2px dashed var(--yps-discount-label-border-color);
    background: var(--yps-discount-label-bg-color);
    color: var(--yps-discount-label-code-color);
    font-weight: bold;
    text-align: center;
  }

  &__amount {
    font-weight: normal;
  }

  &__conditions {
    &_label {
      font-weight: bold;
      list-style-type: none;
      margin-left: -15px;
    }
    &_desc {
      list-style-type: none;
      margin-left: -15px;
    }
    &_price {
      font-weight: bold;
      font-size: 1.4em;
    }
  }
}

.offerAdditionalInfos {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  @include tablet {
    flex-direction: row;
  }
}

.offerPrepaid,
.offerStatus {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 10px 0;
  margin-top: auto;
  text-align: right;

  @include mobile {
    text-align: right;
  }

  @include tablet {
    text-align: left;
  }

  &__label {
    font-size: 9px;
    text-transform: uppercase;
    display: block;
    color: var(--yps-text-color);

    @include tablet {
      display: none;
    }

    &--available {
      color: var(--yps-success-color);
    }
  }

  &__status {
    font-weight: bold;
  }
}

.detailsOverlay {
  background: #fff;
  height: 100vh;
}
