@import '../../assets/scss/abstract/abstract';

@mixin marker() {
  position: absolute;
  z-index: 1;
  font-size: 0;
  cursor: pointer;
  transform: translate(-50%, -100%);

  &:hover {
    z-index: 10;
    @include glow-black(.3, 4px);
  }
}

.marker {
  @include marker;
}

.carsMarker {
  @include marker;

  &__main {
    cursor: pointer;
  }

  &__carsQty {
    display: inline-flex;
    text-align: center;
    justify-content: center;
    padding: 0 5px;
    min-width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    color: var(--yps-bg-color);
    background-color: var(--yps-danger-color);
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
    position: absolute;
    top: -12px;
    left: 50%;
  }

  &__priceRange {
    display: inline-block;
    padding: 0 5px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    color: var(--yps-bg-color);
    background-color: var(--yps-text-color-dark);
    font-size: 11px;
    font-weight: 700;
    border-radius: 12px;
    position: absolute;
    bottom: 0;
    left: 21px;
  }
}

.currentCarMarker {
  &__shape {
    fill: var(--yps-bg-color);
  }

  &__bg {
    fill: var(--yps-primary-color);
  }
}

.stationClusterMarker {
  @include marker;

  &__main {
    width: 100px;
    height: 100px;
    color: var(--yps-bg-color);
    background-color: var(--yps-primary-color);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -10px;
      right: -10px;
      top: -10px;
      bottom: -10px;
      background-color: var(--yps-primary-color);
      opacity: 0.2;
      border-radius: 100%;
    }
  }

  &__count {
    font-weight: 600;
    position: relative;
  }

  &__priceRange {
    display: inline-block;
    padding: 0 5px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    white-space: nowrap;
    color: var(--yps-bg-color);
    background-color: var(--yps-text-color-dark);
    font-size: 11px;
    font-weight: 500;
    border-radius: 12px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 80%);
  }
}


.carVariantMarker {
  @include marker;
  font-weight: bold;
  font-size: 16px;

  &__icon {
    padding: 0!important;
    margin: 0 5px 0 0;
    color: var(--yps-bg-color);
  }

  &__label {
    background-color: var(--yps-primary-color);
    padding: 5px 10px 5px 5px;
    font-size: 14px;
    line-height: 16px;
    color: var(--yps-bg-color);
    display: flex;
    align-items: center;
    border-radius: 3px;

    &--red {
      background-color: var(--yps-danger-color);
    }

    &--green {
      background-color: var(--yps-success-color);
    }

    &--yellow {
      background-color: var(--yps-warn-color);
    }
  }

  &__hoverLabel {
    white-space: nowrap;
    font-size: 12px;
    line-height: 12px;
  }
}

$dotVariantSize: 10px;

.carDotVariantMarker {
  @include marker;
  width: $dotVariantSize;
  height: $dotVariantSize;
  border-radius: $dotVariantSize;
  transform: translate(-50%, -50%);
  border: 1px solid white;

  &--green {
    background-color: var(--yps-success-color);
  }

  &--red {
    background-color: var(--yps-danger-color);
  }

  &__icon {
    display: none;
    padding: 0;
    margin: 0;
    color: var(--yps-bg-color);
  }
}

.stationPinMarker {
  @include marker;

  &__icon {
    fill: var(--yps-bg-color);
  }

  &__bg {
    fill: var(--yps-primary-color);
  }
}

.markerWindow {
  position: absolute;
  z-index: 1;
}

.stationDetails {
  z-index: 20;
  background-color: white;
  font-size: 14px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 10px;
  width: 240px;

  &__name {
    font-weight: 600;
  }

  &__partners {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__logo {
    height: 25px;
  }
}

.carVariantsMap {
  display: grid;
  row-gap: 10px;


  &__title {
    margin: 0 0 5px;
    color: var(--yps-text-color);
  }

  &__mapWrapper {
    display: flex;
    min-height: 200px;

    @include mobile {
      min-height: 500px;
    }
  }
}

.stationMarker {

  &__icon {
    &__bg {
      fill: var(--yps-primary-color);

      &--highlight {
        fill: var(--yps-success-color);
      }
    }

    &__shape {
      fill: var(--yps-bg-color);
    }
  }
}