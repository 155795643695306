@import "../../assets/scss/abstract/abstract";

.tooltip {
  background-color: var(--yps-tooltip-background) !important;
  color: var(--yps-tooltip-text) !important;
  border: 1px solid var(--yps-tooltip-border) !important;
  max-width: 300px !important;
  font-size: small;
  z-index: 110;
  filter: none !important;
  border-radius: 2px !important;

  > div {
    padding: 10px;

    ul {
      margin: 0;
      padding: 5px 15px;
    }
  }

  &__scoringInfo {
    max-width: 500px !important;
  }
}

.scoringDetails {
  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include mobile {
      grid-template-columns: 1fr;
    }
    width: auto;
    gap: 10px;
    > div {
      display: flex;
      flex-direction: column;
    }
  }

  &__description {
    &_item {
      display: flex;
      align-items: flex-start;
      margin: 10px 0 0;
      font-size: small;
    }
  }

  &__label {
    padding: 2px 5px;
    &_main {
      font-weight: 600;
      font-size: 1.2em;
      text-align: center;
      padding: 0 10px 10px;
    }
    &_economy {
      background: var(--yps-score-economy-bg-color);
      color: var(--yps-score-economy-color);
      border: 1px solid var(--yps-score-economy-border-color);
    }
    &_premium {
      background: var(--yps-score-premium-bg-color);
      color: var(--yps-score-premium-color);
      border: 1px solid var(--yps-score-premium-border-color);
    }
    &_basic {
      background: var(--yps-score-basic-bg-color);
      color: var(--yps-score-basic-color);
      border: 1px solid var(--yps-score-basic-border-color);
    }
  }
}
